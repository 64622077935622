'use client';

import Image from 'next/image';
import styles from '../ErrorCommon.module.scss';
import classNames from 'classnames/bind';
import Button from 'ui/common/button/Button';
import { useRouter } from 'next/navigation';
import { SOLUTION_TYPE } from 'ui/util/solution';
import { getDesignV2 } from '@api/design';
import { URL_PATH } from 'ui/util/url.utils';
import { ReactNode } from 'react';

const cx = classNames.bind(styles);

interface Props {
  title?: string;
  description?: ReactNode;
}
const Error404 = ({ title: _title, description: _description }: Props) => {
  const router = useRouter();

  const title = _title ?? '요청하신 페이지를 찾을 수 없어요!';
  const description = _description ?? (
    <>
      페이지가 존재하지 않거나 사용할 수 없는 페이지입니다.
      <br />
      입력하신 주소가 정확한지 다시 한번 확인해주세요.
    </>
  );

  return (
    <div className={cx('error-screen')}>
      <Image src={'/img/icon/icon_layer_blue.svg'} alt={'404Error'} width={104} height={104} />
      <h1>{title}</h1>
      <p>{description}</p>

      <Button
        className={cx('button')}
        onClick={async () => {
          const { pageList } = await getDesignV2({});
          const main = pageList.find((page) => page.mainPageYn)!;

          router.push(`${URL_PATH[SOLUTION_TYPE](`${main.urlPath}`)}`);
        }}
      >
        홈으로 돌아가기
      </Button>
    </div>
  );
};

export default Error404;
