import { axios } from '../RequestClient';
import ISimpleListRsFindPageUrlRs from '@interface/design/ISimpleListRsFindPageUrlRs';
import { IGetDesignMultiLanguageDefaultV1Rs } from '@interface/design/IGetDesignMultiLanguageDefaultV1';
import { Language, LANGUAGE } from 'ui/constants/language';

/** 채용사이트 디자인 조회 **/
export const getDesignV2 = async ({
  languageType = LANGUAGE.KOR,
  prefix,
}: {
  languageType?: Language;
  prefix?: string;
}): Promise<ISimpleListRsFindPageUrlRs> => {
  const { data } = await axios.get(`/design/v2?languageType=${languageType}`, { headers: { prefix } });
  return data;
};

/** 채용사이트 기본값 언어 설정 조회 **/
export const getDesignMultiLanguageDefaultV1 = async (): Promise<IGetDesignMultiLanguageDefaultV1Rs> => {
  try {
    const { data } = await axios.get('/design/v1/multi-language/default');
    return data;
  } catch (e) {
    console.error(e);
    return { useStatus: 'OFF', defaultLanguageType: LANGUAGE.KOR };
  }
};
