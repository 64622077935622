export const replaceInvalidProtocolReg = (value: string, protocol = '') => {
  const protocolAndRest = value.split('://');
  const rest = protocolAndRest.length === 1 ? value : protocolAndRest[1];
  return protocol ? `${protocol}://${rest}` : rest;
};

export const URL_PATH = {
  JOBFLEX: (url: string) => `/career/${url}`,
  JOBDA: (url: string) => `/${url}`,
};
